import SelectApiClientType from '@/api/SelectApiClientType'

export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'isUpdate',
                    inputType: 'checkbox',
                    label: 'labels.update_query',
                    value: 1,
                },
                {
                    key: 'category',
                    label: 'labels.category',
                    inputType: 'select',
                    entity: SelectApiClientType.CUSTOM_EXPORT_CATEGORY,
                    quick: true,
                },
            ]
        },
    },
}
