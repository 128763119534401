export default {
    computed: {
        headers() {
            return [
                {
                    value: 'name',
                    text: this.$t('labels.name'),
                    type: 'string',
                },
                {
                    value: 'description',
                    text: this.$t('labels.description'),
                    type: 'string',
                },
                {
                    value: 'category.name',
                    text: this.$t('labels.category'),
                    type: 'string',
                },
                {
                    value: 'isUpdate',
                    text: this.$t('labels.update_query'),
                    type: 'boolean',
                },
            ]
        },
    },
}
