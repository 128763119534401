<template>
    <div>
        <page-title>{{ $t('menu.custom_export') }}</page-title>
        <customexport-table ref="table">
            <template v-slot:actions>
                <create-button
                    :route-name="routeType.CUSTOM_EXPORT_CREATE"
                ></create-button>
            </template>
        </customexport-table>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import routeType from '@/router/routeType'
import customexportTable from '@/components/pages/customExport/Table'
import CreateButton from '@/components/form/Actions/CreateButton'
import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    components: {
        PageTitle,
        customexportTable,
        CreateButton,
    },
    mixins: [AuthMixin],
    data() {
        return {
            routeType,
        }
    },
}
</script>
