<template>
    <table-container>
        <data-iterator
            ref="iterator"
            :api="api"
            :filters.sync="filters"
            :fixed-filters="fixedFilters"
            @update:filters="updateFilters"
        >
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="listFilters"
                    @input="updateFilters"
                ></table-filters>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    row-select
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <template v-slot:[`item.name`]="{ item }">
                        <v-tooltip
                            top
                            :disabled="!nameTooltipIds.includes(item.id)"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-truncate-tooltip="{
                                        id: item.id,
                                        type: 'nameTooltipIds',
                                    }"
                                    v-bind="attrs"
                                    class="text-truncate"
                                    style="max-width: 20vw"
                                    v-on="on"
                                >
                                    {{ item.name }}
                                </div>
                            </template>
                            <span>{{ item.name }}</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.description`]="{ item }">
                        <v-tooltip
                            top
                            :disabled="!descriptionTooltipIds.includes(item.id)"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-truncate-tooltip="{
                                        id: item.id,
                                        type: 'descriptionTooltipIds',
                                    }"
                                    v-bind="attrs"
                                    class="text-truncate"
                                    style="max-width: 30vw"
                                    v-on="on"
                                >
                                    {{ item.description }}
                                </div>
                            </template>
                            <span>
                                <pre>{{ item.description }}</pre>
                            </span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.category.name`]="{ item }">
                        <template v-if="item.category">
                            <v-tooltip
                                top
                                :disabled="!categoryTooltipIds.includes(item.id)"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-truncate-tooltip="{
                                            id: item.id,
                                            type: 'categoryTooltipIds',
                                        }"
                                        v-bind="attrs"
                                        class="text-truncate"
                                        style="max-width: 10vw"
                                        v-on="on"
                                    >
                                        {{ item.category.name }}
                                    </div>
                                </template>
                                <span>{{ item.category.name }}</span>
                            </v-tooltip>
                        </template>
                    </template>

                    <template v-slot:[`item.isUpdate`]="{ item }">
                        <span v-if="item.isUpdate">
                            <span class="material-icons">done</span>
                        </span>
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button :to="getEditLink(item)"></edit-button>
                        <delete-action
                            :entity-name="item.name"
                            :entity-id="item.id"
                            :api="api"
                            strong
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
            </template>

            <!-- batch actions -->
            <template v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    strong
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Headers from '@/pages/customExport/mixins/Headers'
import Config from '@/config.loader'
import ListFilters from '@/pages/customExport/mixins/ListFilters'

export default {
    directives: {
        truncateTooltip: {
            componentUpdated(el, binding, vnode) {
                if (
                    vnode.context[binding.value.type].includes(binding.value.id)
                ) {
                    return
                }

                if (el.scrollWidth > el.offsetWidth) {
                    vnode.context[binding.value.type].push(binding.value.id)
                }
            },
        },
    },
    mixins: [DataIteratorMixin, DataTableMixin, Headers, ListFilters],
    props: {
        fixedFilters: {
            type: Object,
            default: () => {
                return {
                    order: {
                        position: 'asc',
                    },
                }
            },
        },
    },
    data() {
        return {
            api: RestApiType.CUSTOM_EXPORT,
            baseURL: Config.getConfigValue('VUE_APP_ADMIN_API_BASE'),
            nameTooltipIds: [],
            descriptionTooltipIds: [],
            categoryTooltipIds: [],
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.custom_export')
        },
    },

    methods: {
        getEditLink(item) {
            return {
                name: routeType.CUSTOM_EXPORT_EDIT,
                params: { id: item.id },
            }
        },
    },
}
</script>
